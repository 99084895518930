





















































































































import { Component } from 'vue-property-decorator';
import ListBase from '@/lib/listbase';
import Util from '@/lib/util'
import Youtube from '@/components/youtube.vue';

@Component({
    components: { Youtube },
    watch: {
        e1: function (val) {
            if (val == 3) {
                (this as any).getAmount();
            }
        }
    }
})
export default class Videoteca extends ListBase {
    name: string = 'Videoteca';
    youtubeShow: boolean = false;
    tabModel: any = null;
    url: string = '';
    search: string = '';

    columns = [
        { value: 'video_date', text: 'Fecha', type: 'date', width: 90 },
        { value: 'description', text: 'Curso', type: 'text' },
        { value: 'teacher_name', text: 'Expositor', type: 'text' },
        { value: 'specialty', text: 'Especialidad', type: 'text' },
        { value: 'action', sortable: false },
    ];

    get list() {
        return this.$store.state.video.list;
    }

    get loading() {
        return this.$store.state.video.loading;
    }

    showPlay(item) {
        this.url = item.url;
        this.youtubeShow = !this.youtubeShow;
    }

    /***** INIT EVENTS *****/
    async loadVideos() {
        await this.$store.dispatch({
            type: 'video/getPublicList'
        });
    }

    async mounted() {
        await this.loadVideos();
    }

    created() {

    }

}
