








import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import ListBase from '@/lib/listbase';

@Component({
    components: { }, 
    watch: {
        value: function (val) { 
            let obj = (this as any);
            obj.modalShow = val;
        },
        modalShow: function (val) {
            if (!val) this.$emit('input', false);
        }
    }
})

export default class YouTube extends ListBase {
    @Prop({ type: Boolean, default: false }) value: boolean;
    @Prop({ type: String, default: '' }) url: string;
    modalShow: boolean = false;     

    get youtubeUrl() {
        return 'https://www.youtube.com/embed/' + this.url;
    }

    cancel() {
        this.$emit('input', false);
    }

}
