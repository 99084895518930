var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"inspire"}},[_c('v-app-bar',{attrs:{"app":"","color":"white","flat":""}},[_c('v-container',{staticClass:"py-0 fill-height"},[_c('img',{attrs:{"src":"/static/m.png","alt":"Colegio de Abogados","width":"130"}}),_c('v-toolbar-title',{staticClass:"ml-12"},[_c('v-tabs',{model:{value:(_vm.tabModel),callback:function ($$v) {_vm.tabModel=$$v},expression:"tabModel"}},[_c('v-tab',{key:"video"},[_vm._v("Videoteca")]),_c('v-tab',{key:"manual"},[_vm._v("Manual de Usuario")])],1)],1),_c('v-btn',{attrs:{"depressed":"","icon":"","color":"primary"},on:{"click":_vm.loadVideos}},[_c('v-icon',[_vm._v("refresh")])],1),_c('v-spacer'),_c('v-responsive',{attrs:{"max-width":"260"}},[_c('v-text-field',{attrs:{"dense":"","placeholder":"Buscar...","flat":"","hide-details":"","rounded":"","solo-inverted":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-main',{staticClass:"grey lighten-3",attrs:{"id":"v-widget"}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-sheet',{attrs:{"min-height":"70vh","rounded":"lg"}},[_c('v-tabs-items',{model:{value:(_vm.tabModel),callback:function ($$v) {_vm.tabModel=$$v},expression:"tabModel"}},[_c('v-tab-item',{key:"video"},[_c('v-data-table',{staticClass:"elevation-1 striped",attrs:{"dense":"","headers":_vm.columns,"items":_vm.list,"loading":_vm.loading,"search":_vm.search,"footer-props":{
                                          itemsPerPageOptions: [10,50,100,-1],
                                          itemsPerPageText: _vm.L('Registros por Pagina'),
                                          pageText: '{0}-{1} de {2}',
                                          showFirstLastPage: true,
                                        }},scopedSlots:_vm._u([(_vm.loading)?{key:"loading",fn:function(){return [_c('v-skeleton-loader',{attrs:{"type":"table-row-divider@7"}})]},proxy:true}:null,{key:"item.video_date",fn:function(ref){
                                        var item = ref.item;
return [_vm._v(" "+_vm._s(item.video_date.substr(0,10))+" ")]}},{key:"item.action",fn:function(ref){
                                        var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"red","loading":_vm.loading,"text":"","icon":""},on:{"click":function($event){return _vm.showPlay(item)}}},on),[_c('v-icon',{attrs:{"large":""}},[_vm._v("smart_display")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver Video")])])]}},{key:"no-data",fn:function(){return [_c('v-row',{staticClass:"d-flex flex-wrap text-center justify-space-around my-12",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('v-btn',{attrs:{"icon":"","x-large":""}},[_c('v-icon',{attrs:{"size":"100px","color":"red"}},[_vm._v("smart_display")])],1)],1),_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('h3',{staticClass:"mt-3"},[_vm._v("Aun no hay videos!")])])],1)]},proxy:true}],null,true)})],1),_c('v-tab-item',{key:"manual"},[_c('v-alert',{attrs:{"border":"top","colored-border":"","type":"info","icon":"looks_one","elevation":"2"}},[_vm._v(" Seleccionar el video que desea ver utilizando el filtro superior del sistema, como por ejemplo: Escribir la fecha, el curso, el expositor o la especialidad. ")]),_c('img',{attrs:{"src":"/static/step/manual-step1.jpg","alt":"Colegio de Abogados","width":"1161"}}),_c('v-alert',{attrs:{"border":"top","colored-border":"","type":"info","icon":"looks_two","elevation":"2"}},[_vm._v(" Para ver el video seleccionado solo dar click en el icono reproducir de color rojo y se abrirá una ventana. ")]),_c('img',{attrs:{"src":"/static/step/manual-step2.jpg","alt":"Colegio de Abogados","width":"1161"}}),_c('v-alert',{attrs:{"border":"top","colored-border":"","type":"info","icon":"looks_3","elevation":"2"}},[_vm._v(" Dar click en el botón play y se iniciara la reproducción del video. ")]),_c('div',{staticStyle:{"margin":"0px 20%"}},[_c('img',{attrs:{"src":"/static/step/manual-step3.jpg","alt":"Colegio de Abogados","width":"700"}})])],1)],1)],1)],1)],1)],1)],1),_c('youtube',{attrs:{"url":_vm.url},model:{value:(_vm.youtubeShow),callback:function ($$v) {_vm.youtubeShow=$$v},expression:"youtubeShow"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }